<template>
  <div class="Menu-item" :class="{ active: active == route }" @click="click">
    <router-link :to="route" v-if="icon != 'Tema'">
      <Ajuda v-if="icon === 'Ajuda'" />
      <Assinatura v-if="icon === 'Assinatura'" />
      <Certificado v-if="icon === 'Certificado'" />
      <Comment v-if="icon === 'Comentario'" />
      <Home v-if="icon === 'Home'" />
      <Membro v-if="icon === 'Membro'" />
      {{ name }}
    </router-link>
    <a href="#" v-if="icon == 'Tema'" @click.prevent="setTheme">
      <Tema />

      {{ $t('menu.logout.mudar_tema') }}
    </a>
  </div>
</template>
<script>
import Ajuda from "@/components/icons/ajuda"
import Assinatura from "@/components/icons/assinatura"
import Certificado from "@/components/icons/certificados"
import Comment from '@/components/icons/comentario.vue';
import Home from "@/components/icons/home"
import Membro from "@/components/icons/membros"
import Tema from "@/components/icons/tema"

import Cookies from "js-cookie"

export default {
  components: {
    Ajuda,
    Assinatura,
    Certificado,
    Comment,
    Home,
    Membro,
    Tema
  },
  props: {
    route: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    }
  },
  computed: {
    active() {
      return this.$route.name
    },
  },
  methods: {
    setTheme() {
      let theme = Cookies.get("themeClub")

      if (theme == "light") {
        Cookies.set("themeClub", "dark", { expires: 365 })
      } else {
        Cookies.set("themeClub", "light", { expires: 365 })
      }
      setTimeout(() => {
        this.insertTheme()
      }, 500)
    },
    click() {
      setTimeout(() => {
        document.querySelector(".btn-menu").click();
      }, 500);
    },
    insertTheme() {
      const d = document
      let theme = Cookies.get("themeClub")
      if (theme === undefined || theme === null) {
        d.documentElement.setAttribute("data-theme", "light")
      } else {
        d.documentElement.setAttribute("data-theme", theme)
      }
      this.$root.$emit("loadTheme")
      this.$root.$emit("loadsettheme")
    }
  }
}
</script>

<style lang="scss" scoped>
.Menu-item {
  display: flex;
  align-items: center;
  background-color: var(--backgroundColor);
  border-radius: 5px;
  margin-bottom: 2px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

  a {
    width: 100%;
    padding: 20px;
    display: flex;
    text-decoration: none;
    color: var(--fontcolor);
    font-size: 13.5px;
    font-weight: 600;

    img,
    svg {
      margin-right: 20px;
      width: 17px;
    }
  }

  &:hover {
    background-color: var(--maincolor);
    a {
      color: #fff;

      img,
      svg {
        filter: invert(100%) brightness(1000%);
      }
    }
    cursor: pointer;
  }
}
.active {
  background-color: var(--maincolor);

  a {
    color: #fff;
    img,
    svg {
      filter: invert(100%) brightness(1000%);
    }
  }
  cursor: pointer;
}
</style>